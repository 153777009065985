// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_rT d_gr d_cq";
export var heroHeaderCenter = "t_gs d_gs d_cq d_dt";
export var heroHeaderRight = "t_gt d_gt d_cq d_dv";
export var heroParagraphLeft = "t_rV d_gn d_ct";
export var heroParagraphCenter = "t_gp d_gp d_ct d_dt";
export var heroParagraphRight = "t_gq d_gq d_ct d_dv";
export var heroBtnWrapperLeft = "t_rW d_d0 d_dZ d_t d_bx d_bD";
export var heroBtnWrapperCenter = "t_rX d_d1 d_dZ d_t d_bx d_bB";
export var heroBtnWrapperRight = "t_rY d_d2 d_dZ d_t d_bx d_bF";
export var overlayBtnWrapper = "t_rZ d_gm d_Y d_5 d_6 d_7 d_bl d_b9";
export var design4 = "t_r0 d_gl d_Y d_5 d_6 d_bl";
export var heroExceptionSmall = "t_r1 w_r1";
export var heroExceptionNormal = "t_r2 w_r2";
export var heroExceptionLarge = "t_r3 w_r3";
export var Title1Small = "t_r4 w_r4 w_sx w_sy";
export var Title1Normal = "t_r5 w_r5 w_sx w_sz";
export var Title1Large = "t_r6 w_r6 w_sx w_sB";
export var BodySmall = "t_r7 w_r7 w_sx w_sR";
export var BodyNormal = "t_r8 w_r8 w_sx w_sS";
export var BodyLarge = "t_r9 w_r9 w_sx w_sT";