// extracted by mini-css-extract-plugin
export var lbContainerOuter = "y_t1";
export var lbContainerInner = "y_t2";
export var movingForwards = "y_t3";
export var movingForwardsOther = "y_t4";
export var movingBackwards = "y_t5";
export var movingBackwardsOther = "y_t6";
export var lbImage = "y_t7";
export var lbOtherImage = "y_t8";
export var prevButton = "y_t9";
export var nextButton = "y_vb";
export var closing = "y_vc";
export var appear = "y_vd";