// extracted by mini-css-extract-plugin
export var carouselContainer = "z_q0 d_t d_F d_bc d_X";
export var carouselContainerCards = "z_vf z_q0 d_t d_F d_bc d_X";
export var carouselContainerSides = "z_vg d_t d_F d_X";
export var prevCarouselItem = "z_vh d_t d_F d_Y d_h";
export var prevCarouselItemL = "z_vj z_vh d_t d_F d_Y d_h";
export var moveInFromLeft = "z_vk";
export var prevCarouselItemR = "z_vl z_vh d_t d_F d_Y d_h";
export var moveInFromRight = "z_vm";
export var selectedCarouselItem = "z_vn d_t d_F d_X d_bc";
export var selectedCarouselItemL = "z_vp z_vn d_t d_F d_X d_bc";
export var selectedCarouselItemR = "z_vq z_vn d_t d_F d_X d_bc";
export var nextCarouselItem = "z_vr d_t d_F d_Y d_h";
export var nextCarouselItemL = "z_vs z_vr d_t d_F d_Y d_h";
export var nextCarouselItemR = "z_vt z_vr d_t d_F d_Y d_h";
export var arrowContainer = "z_vv d_F d_Y d_dy d_bj d_2 d_bZ";
export var prevArrowContainer = "z_vw z_vv d_F d_Y d_dy d_bj d_2 d_bZ";
export var prevArrowContainerHidden = "z_vx z_vw z_vv d_F d_Y d_dy d_bj d_2 d_bZ d_h";
export var nextArrowContainer = "z_vy z_vv d_F d_Y d_dy d_bj d_2 d_bZ";
export var carouselContainerProducts = "z_vz";
export var nextArrowContainerHidden = "z_vB z_vy z_vv d_F d_Y d_dy d_bj d_2 d_bZ d_h";
export var arrow = "z_kD d_Y";
export var prevArrow = "z_vC z_kD d_Y";
export var nextArrow = "z_vD z_kD d_Y";
export var carouselIndicatorContainer = "z_vF d_t d_dt d_bj d_bx d_bB d_bM d_bG d_Y";
export var btnsContainer = "z_vG d_t d_bx d_bC";
export var carouselText = "z_vH d_dn d_bx d_bB d_bM d_bZ d_dy d_cB";
export var carouselTextInactive = "z_vJ z_vH d_dn d_bx d_bB d_bM d_bZ d_dy d_cB";
export var indicator = "z_vK d_b5";
export var carouselIndicator = "z_vL z_vK d_b5";
export var carouselIndicatorSelected = "z_vM z_vK d_b5";
export var arrowsContainerTopRight = "z_vN d_Y d_bj d_bz";
export var arrowsContainerBottomLeft = "z_vP d_Y d_bj d_bz";
export var arrowsContainerSides = "z_vQ d_Y d_bj d_bz";
export var smallArrowsBase = "z_vR d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainer = "z_vS z_vR d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainerPrev = "z_vT z_vS z_vR d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainerSmall = "z_vV z_vR d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainerPrevSmall = "z_vW z_vV z_vR d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var icon = "z_qG";
export var iconSmall = "z_vX";
export var multipleWrapper = "z_vY d_bz d_bC d_bc";
export var multipleImage = "z_vZ d_bz";
export var sidesPrevContainer = "z_v0 z_vS z_vR d_bR d_bx d_bB d_bM d_bZ d_dy d_cB d_fl d_Y d_bj d_cB";
export var sidesNextContainer = "z_v1 z_vS z_vR d_bR d_bx d_bB d_bM d_bZ d_dy d_cB d_fl d_Y d_bj d_cB";