// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_rl d_fm d_bD d_ds";
export var alignLeft = "s_qM d_fm d_bD d_ds";
export var alignDiscCenter = "s_rm d_fn d_bB d_dt";
export var alignCenter = "s_bM d_fn d_bB d_dt";
export var alignDiscRight = "s_rn d_fp d_bF d_dv";
export var alignRight = "s_qN d_fp d_bF d_dv";
export var footerContainer = "s_rp d_dT d_bT";
export var footerContainerFull = "s_rq d_dR d_bT";
export var footerHeader = "s_kb d_kb";
export var footerTextWrapper = "s_rr d_t";
export var footerDisclaimerWrapper = "s_kj d_kj d_ch";
export var badgeWrapper = "s_rs d_t d_bx d_bM d_bC d_bG";
export var footerDisclaimerRight = "s_rt undefined";
export var footerDisclaimerLeft = "s_rv undefined";
export var verticalTop = "s_rw d_bx d_bG d_bL d_bJ";
export var firstWide = "s_rx";
export var disclaimer = "s_ry d_bx d_bG";
export var socialDisclaimer = "s_rz";
export var left = "s_rB";
export var wide = "s_rC d_cw";
export var right = "s_rD d_bH";
export var line = "s_fh d_fj d_cs";
export var badgeDisclaimer = "s_rF d_bz d_bM d_bG";
export var badgeContainer = "s_rG d_bx d_bF d_bM";
export var badge = "s_rH";
export var padding = "s_rJ d_c5";
export var end = "s_rK d_bF";
export var linkWrapper = "s_rL d_dy";
export var link = "s_mz d_dy";
export var colWrapper = "s_rM d_cv";
export var consent = "s_f d_f d_bz d_bM";
export var media = "s_rN d_bv d_dw";
export var itemRight = "s_rP";
export var itemLeft = "s_rQ";
export var itemCenter = "s_rR";
export var exceptionWeight = "s_rS w_sZ";