// extracted by mini-css-extract-plugin
export var noMargin = "q_qY d_b9";
export var carouselCol = "q_qZ d_cB";
export var carouselWrapper = "q_k4 d_k4 d_bx d_bB d_bM d_t d_F";
export var carouselImageWrapper = "q_k5 d_k5 d_F d_t";
export var carouselContainer = "q_q0 d_cB";
export var carouselContainerFull = "q_q1 d_dR";
export var carouselImg = "q_mM d_k6 d_t d_F d_bP";
export var imageTextWrapperLeft = "q_kZ d_kZ d_kY d_Y d_bR";
export var imageTextWrapperCenter = "q_k0 d_k0 d_kY d_Y d_bR";
export var imageTextWrapperRight = "q_k1 d_k1 d_kY d_Y d_bR";
export var imageTextWrapperHidden = "q_kX d_kX";
export var imageAltSmall = "q_q2 d_k2";
export var imageAltNormal = "q_q3 d_k2";
export var imageAltLarge = "q_q4 d_k2";
export var imageTextSmall = "q_q5 d_k3";
export var imageTextNormal = "q_q6 d_k3";
export var imageTextLarge = "q_q7 d_k3";