// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "x_fN d_fN d_bx d_bF d_bG";
export var navbarDividedRight = "x_fP d_fP d_bx d_bG";
export var menuLeft = "x_tG d_fL d_bx d_dt d_bM d_bG";
export var menuRight = "x_tH d_fL d_bx d_dt d_bM d_bG";
export var menuCenter = "x_tJ d_fM d_fL d_bx d_dt d_bM d_t d_bB d_bG";
export var menuDivided = "x_pP d_fM d_fL d_bx d_dt d_bM d_t d_bB";
export var navbarItem = "x_pQ d_bv";
export var navbarLogoItemWrapper = "x_fW d_fW d_bz d_bM";
export var burgerToggleVisibleOpen = "x_tK d_f9 d_bw d_X d_bq";
export var burgerToggleVisible = "x_tL d_f9 d_bw d_X d_bq";
export var burgerToggle = "x_tM d_f9 d_bw d_X d_bq d_X";
export var burgerToggleOpen = "x_tN d_f9 d_bw d_X d_bq d_1";
export var burgerInput = "x_tP d_f0 d_t d_F d_bw d_b9 d_dj d_bb d_Y d_6 d_5 d_2 d_7";
export var burgerLine = "x_tQ d_fZ";
export var burgerMenuLeft = "x_tR d_f4 d_f3 d_f1 d_f2 d_Y d_b9 d_dk d_bq d_dt";
export var burgerMenuRight = "x_tS d_f5 d_f3 d_f1 d_f2 d_Y d_b9 d_dk d_bq d_dt";
export var burgerMenuCenter = "x_tT d_f6 d_f3 d_f1 d_f2 d_Y d_b9 d_dk d_bq d_dt";
export var burgerMenuDivided = "x_tV d_f4 d_f3 d_f1 d_f2 d_Y d_b9 d_dk d_bq d_dt";
export var secondary = "x_tW d_bz d_bM";
export var staticBurger = "x_tX";
export var menu = "x_tY";
export var navbarDividedLogo = "x_tZ";
export var nav = "x_t0";